(function () {
    angular.module('informaApp')
        .component('boxPlotChartElement', {
            templateUrl: 'components/summary-view/summary-elements/elements/box-plot-chart-element/template.html',
            controller: BoxPlotChartElement,
            bindings: {
                data: '<',
                settings: '<',
                onStartRedraw: '<',
                onReady: '<'
            }
        });

    function BoxPlotChartElement(BarChartMapper, ObjectPropertyReader) {
        this.$onInit = () => {
            this.onStartRedraw();
            this.onReady();
        };

        this.$onChanges = () => {
            this.chartSource = this.data ? [BarChartMapper.map(this.data)] : [];

            this.hiddenPhases = ObjectPropertyReader.getPropertyOrDefault(this.settings, x => x.hiddenPhases, []);

            this.chartSettings = {
                hideScroll: true,
                hideXAxis: true,
                showNumbers: ObjectPropertyReader.getPropertyOrDefault(this.settings, x => x.showNumbers)
            };
        };
    }
})();